import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Link from "../components/common/Link"

export default function KontaktPage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 138 }) {
        id
        kontakt {
          epost
          instagram
        }
      }
    }
  `)
  const { kontakt } = data.wpPage
  return (
    <Layout>
      <SEO title="Kontakt" />

      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="flex justify-center align-center -mt-24 md:-mt-32">
          <div className="flex flex-col gap-4 items-center md:flex-row bg-white justify-around rounded-xs shadow py-12 px-8 ">
            <div className="bg-secondary rounded-full h-20 w-20 md:h-24 md:w-24 overflow-hidden">
              <div className="h-20 w-20 md:h-24 md:w-24 rounded-full mx-auto md:mr-8 mb-4 md:mb-0 overflow-hidden relative z-10">
                <StaticImage
                  src="../images/jens-om-mig.png"
                  className="h-full w-full"
                />
              </div>
            </div>

            <div className="text-center md:text-left px-2">
              <h2 className="text-xl font-bold mb-1">Jens Almqvist</h2>
              <div className="text-gray-800 text-md">
                <div className="">
                  <a href={`mailto:${kontakt.epost}`}>{kontakt.epost}</a>
                </div>
                <div className="">
                  Instagram: <Link to={kontakt.instagram}>Instagram</Link>
                </div>
                {/* <div className="mb-1">{about.phone}</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="space-y-4">
          <p>Mail: jensalmqvist@gmail.com</p>
          <p>
            Instagram:{" "}
            <Link to="http://instagram.com/jensalmqvist/">@jensalmqvist</Link>
          </p>
        </div> */}
    </Layout>
  )
}
